<template>
  <div>
    <title-link title="投诉" :onClick="onClickBack"></title-link>
    <div style="padding:.2rem">
      <card>
        <template #content>
          <guide-info :guider="guide" :tour="tour"></guide-info>
          <tour-info-top :tour="tour" style="margin-top:.2rem"></tour-info-top>
          <van-divider/>
          <van-row>
            <fieldForm
              :submit="submit"
              :modal="tousu"
              :fieldData="fields"
              v-on:formUpdate="onFormUpdate"
            ></fieldForm>
          </van-row>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import Card from '../components/Card.vue';
import TitleLink from "../components/TitleLink.vue"
import TourInfoTop from '../components/TourInfoTop.vue';
import api from '../../services/apis'
import GuideInfo from '../components/GuideInfo.vue';
import fieldForm from '../components/FieldForm.vue'
export default {
  components: { TitleLink, Card, TourInfoTop,GuideInfo,fieldForm },
  data() {
    return {
      tour: {},
      guide: {},
      fields: [
        {
          title: "投诉对象",
          name: "target",
          type: "select",
          values: ['导游', '旅行社'],
          rule: [{ required: true, message: "" }],
          inline: false
        },
        {
          title: "上传图片",
          name: "images",
          type: "file",
          rule: [],
          max_count: 1,
          inline: false,
        },
        {
          title: "投诉详情",
          name: "feedback_content",
          type: "textarea",
          disabled: false,
          rule: [{ required: true, message: "" }],
          inline: false,
        },
      ],
      tousu: {
        target: "",
        feedback_content: "",
        images: "",
        feedback_type: 3,
        travel_list_id: 0,
        feedbackable_type: "",
        feedbackable_id: 0,
      }
    }
  },
  mounted() {
    const tourId = this.$route.query.id
    this.tousu.travel_list_id = tourId
    api.travel_lists_info_by_id(tourId).then(res => {
      if(res.status === 200) {
        this.tour = res.data
        api.guide(this.tour.guide_id).then(gRes => {
          if(gRes.status === 200) {
            this.guide = gRes.data
          }
        })
      }
    })
  },
  methods: {
    onClickBack() {
      this.$router.push('/tour/main');
    },
    onFormUpdate(data) {
      this.tousu.images = [data.images]
      switch (this.tousu.target) {
        case '导游':
          this.tousu.feedbackable_type = 'Guide'
          this.tousu.feedbackable_id = this.tour.guide_id
          break
        case '旅行社':
          this.tousu.feedbackable_type = 'TravelCompany'
          this.tousu.feedbackable_id = this.tour.list_company.id
          break
        default: break
      }
    },
    submit() {
      api.send_feedback(this.tousu).then(res => {
        if(res.status === 200) {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style scoped>
.title-text {
  font-size: 15px;
  font-weight: bold;
}
::v-deep .van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: #323233;
    line-height: inherit;
    text-align: left;
    background-color: transparent;
    border: 0;
    resize: none;
}
</style>